import { NgModule } from "@angular/core"
import { PreloadAllModules, RouterModule, Routes } from "@angular/router"

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./pages/landing/gustavo/gustavo.module").then((m) => m.GustavoPageModule)
  },
  {
    path: "contact",
    loadChildren: () => import("./pages/contact/contact.module").then((m) => m.ContactPageModule)
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      onSameUrlNavigation: "ignore",
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
