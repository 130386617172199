import { Component } from "@angular/core"
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"]
})
export class AppComponent {
  public appPages = [
    { title: "Acerca de mi", url: "about-me", icon: "person" },
    { title: "Mi Portafolio", url: "portfolio", icon: "folder" },
    { title: "Mi descripción", url: "description", icon: "book" },
    { title: "Mis Habilidades", url: "bottom-info", icon: "list" },
    { title: "Contáctame", url: "contact-element", icon: "mail" }
  ]
  constructor() {}
  public navigateTo(url: string) {
    const element = document.querySelector("." + url)
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }
}
